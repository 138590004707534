import axios from "axios";
import { navigate } from "gatsby";
import { Helmet } from "react-helmet";
import React, { useEffect } from "react";
import MenuBar from "../../components/MenuBar";
import Spinner from "../../components/Spinner";
import { ErrorCodes } from "../../constants";

import useCookie from "../../hooks/cookieHook";
import { useAuthContext } from "../../contexts/authContext";
import useURLQueryParameter from "../../hooks/queryParamHook";

const fetch = async (
  code: string,
  codeVerifier: string,
  oktaDomain: string = "bamfunds-ext.okta.com",
  oktaProtocol: string = "https",
  oktaRedirectURI: string = `${process.env.GATSBY_APPLICATION_URL}/login/okta`,
  oktaClientId: string = process.env.GATSBY_OKTA_CLIENT_ID,
) => {
  const parameters = new URLSearchParams();

  parameters.append("grant_type", "authorization_code");
  parameters.append("client_id", oktaClientId);
  parameters.append("redirect_uri", oktaRedirectURI);
  parameters.append("code", code);
  oktaRedirectURI;
  parameters.append("code_verifier", codeVerifier);

  const { data } = await axios.post(
    new URL("/oauth2/v1/token", `${oktaProtocol}://${oktaDomain}`).href,
    parameters,
  );

  const { access_token, id_token, refresh_token } = data;

  return {
    idToken: id_token,
    accessToken: access_token,
    refreshToken: refresh_token,
  };
};

const LOGIN_TIMEOUT_SECONDS = 20;

const LoginPage = () => {
  const { accessToken, setAccessToken, refreshToken, setRefreshToken } =
    useAuthContext();

  const [codeQueryParameter] = useURLQueryParameter("code");
  const [stateQueryParameter] = useURLQueryParameter("state");

  const [oidcError] = useURLQueryParameter("error");

  const [verifierCookie, , removeVerifierCookie] = useCookie("verifier");
  const [stateCookie, , removeStateCookie] = useCookie("state");

  // https://developer.okta.com/docs/reference/error-codes/
  useEffect(() => {
    if (!oidcError) return;

    let code: ErrorCodes | null = null;

    switch (oidcError) {
      case "unauthorized_client":
        code = ErrorCodes.OktaUnauthorizedClient;
        break;

      case "access_denied":
        code = ErrorCodes.OktaAccessDenied;
        break;

      case "unsupported_response_type":
        code = ErrorCodes.OktaUnsupportedResponseType;
        break;

      case "unsupported_response_mode":
        code = ErrorCodes.OktaUnsupportedResponseMode;
        break;

      case "invalid_scope":
        code = ErrorCodes.OktaInvalidScope;
        break;

      case "server_error":
        code = ErrorCodes.OktaServerError;
        break;

      case "temporarily_unavailable":
        code = ErrorCodes.OktaTemporarilyUnavailable;
        break;

      case "invalid_client":
        code = ErrorCodes.OktaInvalidClient;
        break;

      case "login_required":
        code = ErrorCodes.OktaLoginRequired;
        break;

      case "invalid_request":
        code = ErrorCodes.OktaInvalidRequest;
        break;

      case "user_canceled_request":
        code = ErrorCodes.OktaUserCanceledRequest;
        break;

      default:
        break;
    }

    if (code) navigate(`/error?code=${code}`);
    else navigate("/error");
  }, [oidcError]);

  useEffect(() => {
    const maximumWait = setTimeout(() => {
      navigate(`/error?code=${ErrorCodes.InternalLoginTimeout}`);
    }, LOGIN_TIMEOUT_SECONDS * 1_000);

    return () => clearTimeout(maximumWait);
  });

  useEffect(() => {
    if (oidcError) return;

    if (refreshToken && accessToken) navigate("/terms_of_use");
  }, [refreshToken, accessToken]);

  useEffect(() => {
    if (oidcError) return;

    if (refreshToken && accessToken) {
      return;
    }

    if (
      !(
        codeQueryParameter &&
        stateQueryParameter &&
        verifierCookie &&
        stateCookie
      )
    )
      return;

    if (stateCookie !== stateQueryParameter) return;

    let ignore = false;

    fetch(codeQueryParameter, verifierCookie, "bamfunds-ext.okta.com")
      .catch((_) => {
        if (!ignore) {
          removeVerifierCookie();
          removeStateCookie();
          navigate("/login");
        }
      })
      .then((value) => {
        if (!ignore) {
          /**
           * The order here's important given the hook's
           * `useEffect` implementation! If the refresh token
           * is set first, the null access token will trigger
           * an exchange.
           */
          setAccessToken(value.accessToken);
          setRefreshToken(value.refreshToken);
        }
      });

    return () => {
      ignore = true;
    };
  }, [codeQueryParameter, stateQueryParameter, verifierCookie, stateCookie]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Authenticating...</title>
      </Helmet>
      <main className="w-screen h-screen bg-off-white flex flex-center">
        <MenuBar NorthstarMenu={true} />
        <Spinner active={true} />
      </main>
    </>
  );
};

export const Head = () => <title>Authentication</title>;

export default LoginPage;
